<template>
    <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
        <el-form :model="addFeedbackForm" ref="addFeedbackRef" :rules="addFeedbackrules">
                        <el-form-item label="创建人id" :label-width="formLabelWidth"
                                      prop="createId" v-if="addFeedbackForm.id">
                            <el-input
                                    v-model="addFeedbackForm.createId"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写创建人id"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="openid" :label-width="formLabelWidth"
                                      prop="openid">
                            <el-input
                                    v-model="addFeedbackForm.openid"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写openid"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="动态id" :label-width="formLabelWidth"
                                      prop="worksId" v-if="addFeedbackForm.id">
                            <el-input
                                    v-model="addFeedbackForm.worksId"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写动态id"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="备注" :label-width="formLabelWidth"
                                      prop="remark">
                            <el-input
                                    v-model="addFeedbackForm.remark"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写备注"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="类型" :label-width="formLabelWidth"
                                      prop="type" v-if="addFeedbackForm.id">
                            <el-input
                                    v-model="addFeedbackForm.type"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写类型"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="提交时间" :label-width="formLabelWidth"
                                      prop="createTime" v-if="addFeedbackForm.id">
                            <el-input
                                    v-model="addFeedbackForm.createTime"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写提交时间"
                            ></el-input>
                        </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
    import {ref} from "vue";
    import {httpService} from "@/utils/httpService";
    import {ElMessage, ElForm} from "element-plus";
    import {vxRule} from "@/utils/validator";

    export default {
        name: "AddFeedback",
        components: {ElForm, ElMessage},
        data() {
            return {
                dialogFormVisible: ref(false),
                formLabelWidth: "120px",
                title: "",
                personnelType: "",
                addFeedbackForm: {
                    createId: "",
                    openid: "",
                    worksId: "",
                    remark: "",
                    type: "",
                    createTime: "",
                },
                // 校验规则
                addFeedbackrules: {
                    openid: vxRule(true, null, "blur"),
                    remark: vxRule(true, null, "blur"),
                },
            };
        },
        methods: {
            closeDialog() {
                this.clear();
            },
            cancel() {
                this.dialogFormVisible = false;
                this.clear();
            },
            getFeedbackById(id) {
                httpService("/api/feedback/queryById", {id: id}, "get").then(
                    (data) => {
                        this.addFeedbackForm = data;
                    }
                );
            },
            clear() {
                this.dialogFormVisible = false;
                //清空form
                    this.addFeedbackForm.id = "";
                    this.addFeedbackForm.createId = "";
                    this.addFeedbackForm.openid = "";
                    this.addFeedbackForm.worksId = "";
                    this.addFeedbackForm.remark = "";
                    this.addFeedbackForm.type = "";
                    this.addFeedbackForm.createTime = "";
            },
            save() {
                this.$refs.addFeedbackRef.validate((valid) => {
                    if (valid) {
                        let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
                        params.append("createId", this.addFeedbackForm.createId);
                        params.append("openid", this.addFeedbackForm.openid);
                        params.append("worksId", this.addFeedbackForm.worksId);
                        params.append("remark", this.addFeedbackForm.remark);
                        params.append("type", this.addFeedbackForm.type);
                        params.append("createTime", this.addFeedbackForm.createTime);
                        if (this.addFeedbackForm.id) {
                            params.append("id", this.addFeedbackForm.id);
                            httpService("/api/feedback/edit", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("修改成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getFeedbackList();
                                    } else {
                                        ElMessage.error("修改失败");
                                    }
                                }
                            );
                        } else {
                            httpService("/api/feedback/add", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("添加成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getFeedbackList();
                                    } else {
                                        ElMessage.error("添加失败");
                                    }
                                }
                            );
                        }
                    } else {
                        return false;
                    }
                });
            },
        },
    };
</script>
<style>
</style>